! function(a, b) {
    "object" == typeof exports && "object" == typeof module ? module.exports = b() : "function" == typeof define && define.amd ? define([], b) : "object" == typeof exports ? exports.PUBNUB = b() : a.PUBNUB = b()
}(this, function() {
    return function(a) {
        function b(d) {
            if (c[d]) return c[d].exports;
            var e = c[d] = {
                exports: {},
                id: d,
                loaded: !1
            };
            return a[d].call(e.exports, e, e.exports, b), e.loaded = !0, e.exports
        }
        var c = {};
        return b.m = a, b.c = c, b.p = "", b(0)
    }([function(a, b, c) {
        function d(a, b) {
            var c = q.HmacSHA256(a, b);
            return c.toString(q.enc.Base64)
        }

        function e(a) {
            return document.getElementById(a)
        }

        function f(a) {
            console.error(a)
        }

        function g(a, b) {
            var c = [];
            return s.each(a.split(/\s+/), function(a) {
                s.each((b || document).getElementsByTagName(a), function(a) {
                    c.push(a)
                })
            }), c
        }

        function h(a, b, c) {
            s.each(a.split(","), function(a) {
                var d = function(a) {
                    a || (a = window.event), c(a) || (a.cancelBubble = !0, a.preventDefault && a.preventDefault(), a.stopPropagation && a.stopPropagation())
                };
                b.addEventListener ? b.addEventListener(a, d, !1) : b.attachEvent ? b.attachEvent("on" + a, d) : b["on" + a] = d
            })
        }

        function i() {
            return g("head")[0]
        }

        function j(a, b, c) {
            return c ? void a.setAttribute(b, c) : a && a.getAttribute && a.getAttribute(b)
        }

        function k(a, b) {
            for (var c in b)
                if (b.hasOwnProperty(c)) try {
                    a.style[c] = b[c] + ("|width|height|top|left|".indexOf(c) > 0 && "number" == typeof b[c] ? "px" : "")
                } catch (d) {
                    return
                }
        }

        function l(a) {
            return document.createElement(a)
        }

        function m(a) {
            var b, c, d = 0,
                e = 0,
                f = !0,
                g = a.timeout || s.DEF_TIMEOUT,
                h = a.data || {},
                i = a.fail || function() {},
                j = a.success || function() {},
                k = function(a, e) {
                    d || (d = 1, clearTimeout(c), b && (b.onerror = b.onload = null, b.abort && b.abort(), b = null), a && i(e))
                },
                l = function() {
                    if (!e) {
                        var a;
                        e = 1, clearTimeout(c);
                        try {
                            a = JSON.parse(b.responseText)
                        } catch (d) {
                            return k(1)
                        }
                        j(a)
                    }
                };
            c = s.timeout(function() {
                k(1)
            }, g);
            try {
                b = "undefined" != typeof XDomainRequest && new XDomainRequest || new XMLHttpRequest, b.onerror = b.onabort = function() {
                    k(1, b.responseText || {
                        error: "Network Connection Error"
                    })
                }, b.onload = b.onloadend = l, h.pnsdk = u;
                var m = s.build_url(a.url, h);
                b.open("GET", m, f), f && (b.timeout = g), b.send()
            } catch (n) {
                k(1, {
                    error: "XHR Failed",
                    stacktrace: n
                })
            }
            return k
        }

        function n() {
            if (!("onLine" in navigator)) return 1;
            try {
                return navigator.onLine
            } catch (a) {
                return !0
            }
        }

        function o(a) {
            return "sendBeacon" in navigator && navigator.sendBeacon(a)
        }
        c(1);
        var p = c(2),
            q = c(3),
            r = c(4),
            s = c(5),
            t = c(8),
            u = "PubNub-JS-Web/" + r.version;
        window.console || (window.console = window.console || {}), console.log || (console.log = console.error = (window.opera || {}).postError || function() {});
        var v = function() {
                var a = {},
                    b = !1;
                try {
                    b = window.localStorage
                } catch (c) {
                    return
                }
                var d = function(a) {
                        return document.cookie.indexOf(a) === -1 ? null : ((document.cookie || "").match(RegExp(a + "=([^;]+)")) || [])[1] || null
                    },
                    e = function(a, b) {
                        document.cookie = a + "=" + b + "; expires=Thu, 1 Aug " + ((new Date).getFullYear() + 1) + " 20:00:00 UTC; path=/"
                    },
                    f = function() {
                        try {
                            return e("pnctest", "1"), "1" === d("pnctest")
                        } catch (a) {
                            return !1
                        }
                    }();
                return {
                    get: function(c) {
                        try {
                            return b ? b.getItem(c) : f ? d(c) : a[c]
                        } catch (e) {
                            return a[c]
                        }
                    },
                    set: function(c, d) {
                        try {
                            if (b) return b.setItem(c, d) && 0;
                            f && e(c, d), a[c] = d
                        } catch (g) {
                            a[c] = d
                        }
                    }
                }
            }(),
            w = {
                list: {},
                unbind: function(a) {
                    w.list[a] = []
                },
                bind: function(a, b) {
                    (w.list[a] = w.list[a] || []).push(b)
                },
                fire: function(a, b) {
                    s.each(w.list[a] || [], function(a) {
                        a(b)
                    })
                }
            },
            x = function(a) {
                var b = a.leave_on_unload || 0;
                a.xdr = m, a.db = v, a.error = a.error || f, a._is_online = n, a.hmac_SHA256 = d, a.crypto_obj = p(), a.sendBeacon = o, a.params = {
                    pnsdk: u
                };
                var c = function(a) {
                        return x(a)
                    },
                    q = s.PN_API(a);
                for (var r in q) q.hasOwnProperty(r) && (c[r] = q[r]);
                return c.css = k, c.$ = e, c.create = l, c.bind = h, c.head = i, c.search = g, c.attr = j, c.events = w, c.init = c, c.secure = c, c.crypto_obj = p(), c.WS = t, c.PNmessage = s.PNmessage, c.supplant = s.supplant, h("beforeunload", window, function() {
                    return b && c["each-channel"](function(a) {
                        c.LEAVE(a.name, 0)
                    }), !0
                }), c.ready(), a.notest ? c : (h("offline", window, c.offline), h("offline", document, c.offline), c)
            };
        x.init = x, x.secure = x, x.crypto_obj = p(), x.WS = t, x.db = v, x.PNmessage = s.PNmessage, x.uuid = s.uuid, x.css = k, x.$ = e, x.create = e, x.bind = h, x.head = i, x.search = g, x.attr = j, x.events = w, x.map = s.map, x.each = s.each, x.grep = s.grep, x.supplant = s.supplant, x.now = s.now, x.unique = s.unique, x.updater = s.updater, window.jQuery && (window.jQuery.PUBNUB = x), a.exports = x
    }, function(module, exports) {
        (function() {
            window.JSON && window.JSON.stringify || function() {
                function toJSON(a) {
                    try {
                        return this.valueOf()
                    } catch (b) {
                        return null
                    }
                }

                function quote(a) {
                    return escapable.lastIndex = 0, escapable.test(a) ? '"' + a.replace(escapable, function(a) {
                        var b = meta[a];
                        return "string" == typeof b ? b : "\\u" + ("0000" + a.charCodeAt(0).toString(16)).slice(-4)
                    }) + '"' : '"' + a + '"'
                }

                function str(a, b) {
                    var c, d, e, f, g, h = gap,
                        i = b[a];
                    switch (i && "object" == typeof i && (i = toJSON.call(i, a)), "function" == typeof rep && (i = rep.call(b, a, i)), typeof i) {
                        case "string":
                            return quote(i);
                        case "number":
                            return isFinite(i) ? String(i) : "null";
                        case "boolean":
                        case "null":
                            return String(i);
                        case "object":
                            if (!i) return "null";
                            if (gap += indent, g = [], "[object Array]" === Object.prototype.toString.apply(i)) {
                                for (f = i.length, c = 0; c < f; c += 1) g[c] = str(c, i) || "null";
                                return e = 0 === g.length ? "[]" : gap ? "[\n" + gap + g.join(",\n" + gap) + "\n" + h + "]" : "[" + g.join(",") + "]", gap = h, e
                            }
                            if (rep && "object" == typeof rep)
                                for (f = rep.length, c = 0; c < f; c += 1) d = rep[c], "string" == typeof d && (e = str(d, i), e && g.push(quote(d) + (gap ? ": " : ":") + e));
                            else
                                for (d in i) Object.hasOwnProperty.call(i, d) && (e = str(d, i), e && g.push(quote(d) + (gap ? ": " : ":") + e));
                            return e = 0 === g.length ? "{}" : gap ? "{\n" + gap + g.join(",\n" + gap) + "\n" + h + "}" : "{" + g.join(",") + "}", gap = h, e
                    }
                }
                window.JSON || (window.JSON = {});
                var cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
                    escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
                    gap, indent, meta = {
                        "\b": "\\b",
                        "\t": "\\t",
                        "\n": "\\n",
                        "\f": "\\f",
                        "\r": "\\r",
                        '"': '\\"',
                        "\\": "\\\\"
                    },
                    rep;
                "function" != typeof JSON.stringify && (JSON.stringify = function(a, b, c) {
                    var d;
                    if (gap = "", indent = "", "number" == typeof c)
                        for (d = 0; d < c; d += 1) indent += " ";
                    else "string" == typeof c && (indent = c);
                    if (rep = b, b && "function" != typeof b && ("object" != typeof b || "number" != typeof b.length)) throw new Error("JSON.stringify");
                    return str("", {
                        "": a
                    })
                }), "function" != typeof JSON.parse && (JSON.parse = function(text) {
                    return eval("(" + text + ")")
                })
            }()
        }).call(window)
    }, function(a, b, c) {
        function d() {
            function a(a) {
                return e.SHA256(a).toString(e.enc.Hex)
            }

            function b(a) {
                return a = a || {}, a.hasOwnProperty("encryptKey") || (a.encryptKey = l.encryptKey), a.hasOwnProperty("keyEncoding") || (a.keyEncoding = l.keyEncoding), a.hasOwnProperty("keyLength") || (a.keyLength = l.keyLength), a.hasOwnProperty("mode") || (a.mode = l.mode), i.indexOf(a.keyEncoding.toLowerCase()) == -1 && (a.keyEncoding = l.keyEncoding), j.indexOf(parseInt(a.keyLength, 10)) == -1 && (a.keyLength = l.keyLength), k.indexOf(a.mode.toLowerCase()) == -1 && (a.mode = l.mode), a
            }

            function c(a, b) {
                return "base64" === b.keyEncoding ? e.enc.Base64.parse(a) : "hex" === b.keyEncoding ? e.enc.Hex.parse(a) : a
            }

            function d(b, d) {
                return b = c(b, d), d.encryptKey ? e.enc.Utf8.parse(a(b).slice(0, 32)) : b
            }

            function f(a) {
                return "ecb" === a.mode ? e.mode.ECB : e.mode.CBC
            }

            function g(a) {
                return "cbc" === a.mode ? e.enc.Utf8.parse(h) : null
            }
            var h = "0123456789012345",
                i = ["hex", "utf8", "base64", "binary"],
                j = [128, 256],
                k = ["ecb", "cbc"],
                l = {
                    encryptKey: !0,
                    keyEncoding: "utf8",
                    keyLength: 256,
                    mode: "cbc"
                };
            return {
                encrypt: function(a, c, h) {
                    if (!c) return a;
                    h = b(h);
                    var i = g(h),
                        j = f(h),
                        k = d(c, h),
                        l = JSON.stringify(a),
                        m = e.AES.encrypt(l, k, {
                            iv: i,
                            mode: j
                        }).ciphertext,
                        n = m.toString(e.enc.Base64);
                    return n || a
                },
                decrypt: function(a, c, h) {
                    if (!c) return a;
                    h = b(h);
                    var i = g(h),
                        j = f(h),
                        k = d(c, h);
                    try {
                        var l = e.enc.Base64.parse(a),
                            m = e.AES.decrypt({
                                ciphertext: l
                            }, k, {
                                iv: i,
                                mode: j
                            }).toString(e.enc.Utf8),
                            n = JSON.parse(m);
                        return n
                    } catch (o) {
                        return
                    }
                }
            }
        }
        var e = c(3);
        a.exports = d
    }, function(a, b) {
        var c = c || function(a, b) {
            var c = {},
                d = c.lib = {},
                e = function() {},
                f = d.Base = {
                    extend: function(a) {
                        e.prototype = this;
                        var b = new e;
                        return a && b.mixIn(a), b.hasOwnProperty("init") || (b.init = function() {
                            b.$super.init.apply(this, arguments)
                        }), b.init.prototype = b, b.$super = this, b
                    },
                    create: function() {
                        var a = this.extend();
                        return a.init.apply(a, arguments), a
                    },
                    init: function() {},
                    mixIn: function(a) {
                        for (var b in a) a.hasOwnProperty(b) && (this[b] = a[b]);
                        a.hasOwnProperty("toString") && (this.toString = a.toString)
                    },
                    clone: function() {
                        return this.init.prototype.extend(this)
                    }
                },
                g = d.WordArray = f.extend({
                    init: function(a, c) {
                        a = this.words = a || [], this.sigBytes = c != b ? c : 4 * a.length
                    },
                    toString: function(a) {
                        return (a || i).stringify(this)
                    },
                    concat: function(a) {
                        var b = this.words,
                            c = a.words,
                            d = this.sigBytes;
                        if (a = a.sigBytes, this.clamp(), d % 4)
                            for (var e = 0; e < a; e++) b[d + e >>> 2] |= (c[e >>> 2] >>> 24 - 8 * (e % 4) & 255) << 24 - 8 * ((d + e) % 4);
                        else if (65535 < c.length)
                            for (e = 0; e < a; e += 4) b[d + e >>> 2] = c[e >>> 2];
                        else b.push.apply(b, c);
                        return this.sigBytes += a, this
                    },
                    clamp: function() {
                        var b = this.words,
                            c = this.sigBytes;
                        b[c >>> 2] &= 4294967295 << 32 - 8 * (c % 4), b.length = a.ceil(c / 4)
                    },
                    clone: function() {
                        var a = f.clone.call(this);
                        return a.words = this.words.slice(0), a
                    },
                    random: function(b) {
                        for (var c = [], d = 0; d < b; d += 4) c.push(4294967296 * a.random() | 0);
                        return new g.init(c, b)
                    }
                }),
                h = c.enc = {},
                i = h.Hex = {
                    stringify: function(a) {
                        var b = a.words;
                        a = a.sigBytes;
                        for (var c = [], d = 0; d < a; d++) {
                            var e = b[d >>> 2] >>> 24 - 8 * (d % 4) & 255;
                            c.push((e >>> 4).toString(16)), c.push((15 & e).toString(16))
                        }
                        return c.join("")
                    },
                    parse: function(a) {
                        for (var b = a.length, c = [], d = 0; d < b; d += 2) c[d >>> 3] |= parseInt(a.substr(d, 2), 16) << 24 - 4 * (d % 8);
                        return new g.init(c, b / 2)
                    }
                },
                j = h.Latin1 = {
                    stringify: function(a) {
                        var b = a.words;
                        a = a.sigBytes;
                        for (var c = [], d = 0; d < a; d++) c.push(String.fromCharCode(b[d >>> 2] >>> 24 - 8 * (d % 4) & 255));
                        return c.join("")
                    },
                    parse: function(a) {
                        for (var b = a.length, c = [], d = 0; d < b; d++) c[d >>> 2] |= (255 & a.charCodeAt(d)) << 24 - 8 * (d % 4);
                        return new g.init(c, b)
                    }
                },
                k = h.Utf8 = {
                    stringify: function(a) {
                        try {
                            return decodeURIComponent(escape(j.stringify(a)))
                        } catch (b) {
                            throw Error("Malformed UTF-8 data")
                        }
                    },
                    parse: function(a) {
                        return j.parse(unescape(encodeURIComponent(a)))
                    }
                },
                l = d.BufferedBlockAlgorithm = f.extend({
                    reset: function() {
                        this._data = new g.init, this._nDataBytes = 0
                    },
                    _append: function(a) {
                        "string" == typeof a && (a = k.parse(a)), this._data.concat(a), this._nDataBytes += a.sigBytes
                    },
                    _process: function(b) {
                        var c = this._data,
                            d = c.words,
                            e = c.sigBytes,
                            f = this.blockSize,
                            h = e / (4 * f),
                            h = b ? a.ceil(h) : a.max((0 | h) - this._minBufferSize, 0);
                        if (b = h * f, e = a.min(4 * b, e), b) {
                            for (var i = 0; i < b; i += f) this._doProcessBlock(d, i);
                            i = d.splice(0, b), c.sigBytes -= e
                        }
                        return new g.init(i, e)
                    },
                    clone: function() {
                        var a = f.clone.call(this);
                        return a._data = this._data.clone(), a
                    },
                    _minBufferSize: 0
                });
            d.Hasher = l.extend({
                cfg: f.extend(),
                init: function(a) {
                    this.cfg = this.cfg.extend(a), this.reset()
                },
                reset: function() {
                    l.reset.call(this), this._doReset()
                },
                update: function(a) {
                    return this._append(a), this._process(), this
                },
                finalize: function(a) {
                    return a && this._append(a), this._doFinalize()
                },
                blockSize: 16,
                _createHelper: function(a) {
                    return function(b, c) {
                        return new a.init(c).finalize(b)
                    }
                },
                _createHmacHelper: function(a) {
                    return function(b, c) {
                        return new m.HMAC.init(a, c).finalize(b)
                    }
                }
            });
            var m = c.algo = {};
            return c
        }(Math);
        ! function(a) {
            for (var b = c, d = b.lib, e = d.WordArray, f = d.Hasher, d = b.algo, g = [], h = [], i = function(a) {
                    return 4294967296 * (a - (0 | a)) | 0
                }, j = 2, k = 0; 64 > k;) {
                var l;
                a: {
                    l = j;
                    for (var m = a.sqrt(l), n = 2; n <= m; n++)
                        if (!(l % n)) {
                            l = !1;
                            break a
                        }
                    l = !0
                }
                l && (8 > k && (g[k] = i(a.pow(j, .5))), h[k] = i(a.pow(j, 1 / 3)), k++), j++
            }
            var o = [],
                d = d.SHA256 = f.extend({
                    _doReset: function() {
                        this._hash = new e.init(g.slice(0))
                    },
                    _doProcessBlock: function(a, b) {
                        for (var c = this._hash.words, d = c[0], e = c[1], f = c[2], g = c[3], i = c[4], j = c[5], k = c[6], l = c[7], m = 0; 64 > m; m++) {
                            if (16 > m) o[m] = 0 | a[b + m];
                            else {
                                var n = o[m - 15],
                                    p = o[m - 2];
                                o[m] = ((n << 25 | n >>> 7) ^ (n << 14 | n >>> 18) ^ n >>> 3) + o[m - 7] + ((p << 15 | p >>> 17) ^ (p << 13 | p >>> 19) ^ p >>> 10) + o[m - 16]
                            }
                            n = l + ((i << 26 | i >>> 6) ^ (i << 21 | i >>> 11) ^ (i << 7 | i >>> 25)) + (i & j ^ ~i & k) + h[m] + o[m], p = ((d << 30 | d >>> 2) ^ (d << 19 | d >>> 13) ^ (d << 10 | d >>> 22)) + (d & e ^ d & f ^ e & f), l = k, k = j, j = i, i = g + n | 0, g = f, f = e, e = d, d = n + p | 0
                        }
                        c[0] = c[0] + d | 0, c[1] = c[1] + e | 0, c[2] = c[2] + f | 0, c[3] = c[3] + g | 0, c[4] = c[4] + i | 0, c[5] = c[5] + j | 0, c[6] = c[6] + k | 0, c[7] = c[7] + l | 0
                    },
                    _doFinalize: function() {
                        var b = this._data,
                            c = b.words,
                            d = 8 * this._nDataBytes,
                            e = 8 * b.sigBytes;
                        return c[e >>> 5] |= 128 << 24 - e % 32, c[(e + 64 >>> 9 << 4) + 14] = a.floor(d / 4294967296), c[(e + 64 >>> 9 << 4) + 15] = d, b.sigBytes = 4 * c.length, this._process(), this._hash
                    },
                    clone: function() {
                        var a = f.clone.call(this);
                        return a._hash = this._hash.clone(), a
                    }
                });
            b.SHA256 = f._createHelper(d), b.HmacSHA256 = f._createHmacHelper(d)
        }(Math),
        function() {
            var a = c,
                b = a.enc.Utf8;
            a.algo.HMAC = a.lib.Base.extend({
                init: function(a, c) {
                    a = this._hasher = new a.init, "string" == typeof c && (c = b.parse(c));
                    var d = a.blockSize,
                        e = 4 * d;
                    c.sigBytes > e && (c = a.finalize(c)), c.clamp();
                    for (var f = this._oKey = c.clone(), g = this._iKey = c.clone(), h = f.words, i = g.words, j = 0; j < d; j++) h[j] ^= 1549556828, i[j] ^= 909522486;
                    f.sigBytes = g.sigBytes = e, this.reset()
                },
                reset: function() {
                    var a = this._hasher;
                    a.reset(), a.update(this._iKey)
                },
                update: function(a) {
                    return this._hasher.update(a), this
                },
                finalize: function(a) {
                    var b = this._hasher;
                    return a = b.finalize(a), b.reset(), b.finalize(this._oKey.clone().concat(a))
                }
            })
        }(),
        function() {
            var a = c,
                b = a.lib.WordArray;
            a.enc.Base64 = {
                stringify: function(a) {
                    var b = a.words,
                        c = a.sigBytes,
                        d = this._map;
                    a.clamp(), a = [];
                    for (var e = 0; e < c; e += 3)
                        for (var f = (b[e >>> 2] >>> 24 - 8 * (e % 4) & 255) << 16 | (b[e + 1 >>> 2] >>> 24 - 8 * ((e + 1) % 4) & 255) << 8 | b[e + 2 >>> 2] >>> 24 - 8 * ((e + 2) % 4) & 255, g = 0; 4 > g && e + .75 * g < c; g++) a.push(d.charAt(f >>> 6 * (3 - g) & 63));
                    if (b = d.charAt(64))
                        for (; a.length % 4;) a.push(b);
                    return a.join("")
                },
                parse: function(a) {
                    var c = a.length,
                        d = this._map,
                        e = d.charAt(64);
                    e && (e = a.indexOf(e), -1 != e && (c = e));
                    for (var e = [], f = 0, g = 0; g < c; g++)
                        if (g % 4) {
                            var h = d.indexOf(a.charAt(g - 1)) << 2 * (g % 4),
                                i = d.indexOf(a.charAt(g)) >>> 6 - 2 * (g % 4);
                            e[f >>> 2] |= (h | i) << 24 - 8 * (f % 4), f++
                        }
                    return b.create(e, f)
                },
                _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
            }
        }(),
        function(a) {
            function b(a, b, c, d, e, f, g) {
                return a = a + (b & c | ~b & d) + e + g, (a << f | a >>> 32 - f) + b
            }

            function d(a, b, c, d, e, f, g) {
                return a = a + (b & d | c & ~d) + e + g, (a << f | a >>> 32 - f) + b
            }

            function e(a, b, c, d, e, f, g) {
                return a = a + (b ^ c ^ d) + e + g, (a << f | a >>> 32 - f) + b
            }

            function f(a, b, c, d, e, f, g) {
                return a = a + (c ^ (b | ~d)) + e + g, (a << f | a >>> 32 - f) + b
            }
            for (var g = c, h = g.lib, i = h.WordArray, j = h.Hasher, h = g.algo, k = [], l = 0; 64 > l; l++) k[l] = 4294967296 * a.abs(a.sin(l + 1)) | 0;
            h = h.MD5 = j.extend({
                _doReset: function() {
                    this._hash = new i.init([1732584193, 4023233417, 2562383102, 271733878])
                },
                _doProcessBlock: function(a, c) {
                    for (var g = 0; 16 > g; g++) {
                        var h = c + g,
                            i = a[h];
                        a[h] = 16711935 & (i << 8 | i >>> 24) | 4278255360 & (i << 24 | i >>> 8)
                    }
                    var g = this._hash.words,
                        h = a[c + 0],
                        i = a[c + 1],
                        j = a[c + 2],
                        l = a[c + 3],
                        m = a[c + 4],
                        n = a[c + 5],
                        o = a[c + 6],
                        p = a[c + 7],
                        q = a[c + 8],
                        r = a[c + 9],
                        s = a[c + 10],
                        t = a[c + 11],
                        u = a[c + 12],
                        v = a[c + 13],
                        w = a[c + 14],
                        x = a[c + 15],
                        y = g[0],
                        z = g[1],
                        A = g[2],
                        B = g[3],
                        y = b(y, z, A, B, h, 7, k[0]),
                        B = b(B, y, z, A, i, 12, k[1]),
                        A = b(A, B, y, z, j, 17, k[2]),
                        z = b(z, A, B, y, l, 22, k[3]),
                        y = b(y, z, A, B, m, 7, k[4]),
                        B = b(B, y, z, A, n, 12, k[5]),
                        A = b(A, B, y, z, o, 17, k[6]),
                        z = b(z, A, B, y, p, 22, k[7]),
                        y = b(y, z, A, B, q, 7, k[8]),
                        B = b(B, y, z, A, r, 12, k[9]),
                        A = b(A, B, y, z, s, 17, k[10]),
                        z = b(z, A, B, y, t, 22, k[11]),
                        y = b(y, z, A, B, u, 7, k[12]),
                        B = b(B, y, z, A, v, 12, k[13]),
                        A = b(A, B, y, z, w, 17, k[14]),
                        z = b(z, A, B, y, x, 22, k[15]),
                        y = d(y, z, A, B, i, 5, k[16]),
                        B = d(B, y, z, A, o, 9, k[17]),
                        A = d(A, B, y, z, t, 14, k[18]),
                        z = d(z, A, B, y, h, 20, k[19]),
                        y = d(y, z, A, B, n, 5, k[20]),
                        B = d(B, y, z, A, s, 9, k[21]),
                        A = d(A, B, y, z, x, 14, k[22]),
                        z = d(z, A, B, y, m, 20, k[23]),
                        y = d(y, z, A, B, r, 5, k[24]),
                        B = d(B, y, z, A, w, 9, k[25]),
                        A = d(A, B, y, z, l, 14, k[26]),
                        z = d(z, A, B, y, q, 20, k[27]),
                        y = d(y, z, A, B, v, 5, k[28]),
                        B = d(B, y, z, A, j, 9, k[29]),
                        A = d(A, B, y, z, p, 14, k[30]),
                        z = d(z, A, B, y, u, 20, k[31]),
                        y = e(y, z, A, B, n, 4, k[32]),
                        B = e(B, y, z, A, q, 11, k[33]),
                        A = e(A, B, y, z, t, 16, k[34]),
                        z = e(z, A, B, y, w, 23, k[35]),
                        y = e(y, z, A, B, i, 4, k[36]),
                        B = e(B, y, z, A, m, 11, k[37]),
                        A = e(A, B, y, z, p, 16, k[38]),
                        z = e(z, A, B, y, s, 23, k[39]),
                        y = e(y, z, A, B, v, 4, k[40]),
                        B = e(B, y, z, A, h, 11, k[41]),
                        A = e(A, B, y, z, l, 16, k[42]),
                        z = e(z, A, B, y, o, 23, k[43]),
                        y = e(y, z, A, B, r, 4, k[44]),
                        B = e(B, y, z, A, u, 11, k[45]),
                        A = e(A, B, y, z, x, 16, k[46]),
                        z = e(z, A, B, y, j, 23, k[47]),
                        y = f(y, z, A, B, h, 6, k[48]),
                        B = f(B, y, z, A, p, 10, k[49]),
                        A = f(A, B, y, z, w, 15, k[50]),
                        z = f(z, A, B, y, n, 21, k[51]),
                        y = f(y, z, A, B, u, 6, k[52]),
                        B = f(B, y, z, A, l, 10, k[53]),
                        A = f(A, B, y, z, s, 15, k[54]),
                        z = f(z, A, B, y, i, 21, k[55]),
                        y = f(y, z, A, B, q, 6, k[56]),
                        B = f(B, y, z, A, x, 10, k[57]),
                        A = f(A, B, y, z, o, 15, k[58]),
                        z = f(z, A, B, y, v, 21, k[59]),
                        y = f(y, z, A, B, m, 6, k[60]),
                        B = f(B, y, z, A, t, 10, k[61]),
                        A = f(A, B, y, z, j, 15, k[62]),
                        z = f(z, A, B, y, r, 21, k[63]);
                    g[0] = g[0] + y | 0, g[1] = g[1] + z | 0, g[2] = g[2] + A | 0, g[3] = g[3] + B | 0
                },
                _doFinalize: function() {
                    var b = this._data,
                        c = b.words,
                        d = 8 * this._nDataBytes,
                        e = 8 * b.sigBytes;
                    c[e >>> 5] |= 128 << 24 - e % 32;
                    var f = a.floor(d / 4294967296);
                    for (c[(e + 64 >>> 9 << 4) + 15] = 16711935 & (f << 8 | f >>> 24) | 4278255360 & (f << 24 | f >>> 8), c[(e + 64 >>> 9 << 4) + 14] = 16711935 & (d << 8 | d >>> 24) | 4278255360 & (d << 24 | d >>> 8), b.sigBytes = 4 * (c.length + 1), this._process(), b = this._hash, c = b.words, d = 0; 4 > d; d++) e = c[d], c[d] = 16711935 & (e << 8 | e >>> 24) | 4278255360 & (e << 24 | e >>> 8);
                    return b
                },
                clone: function() {
                    var a = j.clone.call(this);
                    return a._hash = this._hash.clone(), a
                }
            }), g.MD5 = j._createHelper(h), g.HmacMD5 = j._createHmacHelper(h)
        }(Math),
        function() {
            var a = c,
                b = a.lib,
                d = b.Base,
                e = b.WordArray,
                b = a.algo,
                f = b.EvpKDF = d.extend({
                    cfg: d.extend({
                        keySize: 4,
                        hasher: b.MD5,
                        iterations: 1
                    }),
                    init: function(a) {
                        this.cfg = this.cfg.extend(a)
                    },
                    compute: function(a, b) {
                        for (var c = this.cfg, d = c.hasher.create(), f = e.create(), g = f.words, h = c.keySize, c = c.iterations; g.length < h;) {
                            i && d.update(i);
                            var i = d.update(a).finalize(b);
                            d.reset();
                            for (var j = 1; j < c; j++) i = d.finalize(i), d.reset();
                            f.concat(i)
                        }
                        return f.sigBytes = 4 * h, f
                    }
                });
            a.EvpKDF = function(a, b, c) {
                return f.create(c).compute(a, b)
            }
        }(), c.lib.Cipher || function(a) {
                var b = c,
                    d = b.lib,
                    e = d.Base,
                    f = d.WordArray,
                    g = d.BufferedBlockAlgorithm,
                    h = b.enc.Base64,
                    i = b.algo.EvpKDF,
                    j = d.Cipher = g.extend({
                        cfg: e.extend(),
                        createEncryptor: function(a, b) {
                            return this.create(this._ENC_XFORM_MODE, a, b)
                        },
                        createDecryptor: function(a, b) {
                            return this.create(this._DEC_XFORM_MODE, a, b)
                        },
                        init: function(a, b, c) {
                            this.cfg = this.cfg.extend(c), this._xformMode = a, this._key = b, this.reset()
                        },
                        reset: function() {
                            g.reset.call(this), this._doReset()
                        },
                        process: function(a) {
                            return this._append(a), this._process()
                        },
                        finalize: function(a) {
                            return a && this._append(a), this._doFinalize()
                        },
                        keySize: 4,
                        ivSize: 4,
                        _ENC_XFORM_MODE: 1,
                        _DEC_XFORM_MODE: 2,
                        _createHelper: function(a) {
                            return {
                                encrypt: function(b, c, d) {
                                    return ("string" == typeof c ? p : o).encrypt(a, b, c, d)
                                },
                                decrypt: function(b, c, d) {
                                    return ("string" == typeof c ? p : o).decrypt(a, b, c, d)
                                }
                            }
                        }
                    });
                d.StreamCipher = j.extend({
                    _doFinalize: function() {
                        return this._process(!0)
                    },
                    blockSize: 1
                });
                var k = b.mode = {},
                    l = function(b, c, d) {
                        var e = this._iv;
                        e ? this._iv = a : e = this._prevBlock;
                        for (var f = 0; f < d; f++) b[c + f] ^= e[f]
                    },
                    m = (d.BlockCipherMode = e.extend({
                        createEncryptor: function(a, b) {
                            return this.Encryptor.create(a, b)
                        },
                        createDecryptor: function(a, b) {
                            return this.Decryptor.create(a, b)
                        },
                        init: function(a, b) {
                            this._cipher = a, this._iv = b
                        }
                    })).extend();
                m.Encryptor = m.extend({
                    processBlock: function(a, b) {
                        var c = this._cipher,
                            d = c.blockSize;
                        l.call(this, a, b, d), c.encryptBlock(a, b), this._prevBlock = a.slice(b, b + d)
                    }
                }), m.Decryptor = m.extend({
                    processBlock: function(a, b) {
                        var c = this._cipher,
                            d = c.blockSize,
                            e = a.slice(b, b + d);
                        c.decryptBlock(a, b), l.call(this, a, b, d), this._prevBlock = e
                    }
                }), k = k.CBC = m, m = (b.pad = {}).Pkcs7 = {
                    pad: function(a, b) {
                        for (var c = 4 * b, c = c - a.sigBytes % c, d = c << 24 | c << 16 | c << 8 | c, e = [], g = 0; g < c; g += 4) e.push(d);
                        c = f.create(e, c), a.concat(c)
                    },
                    unpad: function(a) {
                        a.sigBytes -= 255 & a.words[a.sigBytes - 1 >>> 2]
                    }
                }, d.BlockCipher = j.extend({
                    cfg: j.cfg.extend({
                        mode: k,
                        padding: m
                    }),
                    reset: function() {
                        j.reset.call(this);
                        var a = this.cfg,
                            b = a.iv,
                            a = a.mode;
                        if (this._xformMode == this._ENC_XFORM_MODE) var c = a.createEncryptor;
                        else c = a.createDecryptor, this._minBufferSize = 1;
                        this._mode = c.call(a, this, b && b.words)
                    },
                    _doProcessBlock: function(a, b) {
                        this._mode.processBlock(a, b)
                    },
                    _doFinalize: function() {
                        var a = this.cfg.padding;
                        if (this._xformMode == this._ENC_XFORM_MODE) {
                            a.pad(this._data, this.blockSize);
                            var b = this._process(!0)
                        } else b = this._process(!0), a.unpad(b);
                        return b
                    },
                    blockSize: 4
                });
                var n = d.CipherParams = e.extend({
                        init: function(a) {
                            this.mixIn(a)
                        },
                        toString: function(a) {
                            return (a || this.formatter).stringify(this)
                        }
                    }),
                    k = (b.format = {}).OpenSSL = {
                        stringify: function(a) {
                            var b = a.ciphertext;
                            return a = a.salt, (a ? f.create([1398893684, 1701076831]).concat(a).concat(b) : b).toString(h)
                        },
                        parse: function(a) {
                            a = h.parse(a);
                            var b = a.words;
                            if (1398893684 == b[0] && 1701076831 == b[1]) {
                                var c = f.create(b.slice(2, 4));
                                b.splice(0, 4), a.sigBytes -= 16
                            }
                            return n.create({
                                ciphertext: a,
                                salt: c
                            })
                        }
                    },
                    o = d.SerializableCipher = e.extend({
                        cfg: e.extend({
                            format: k
                        }),
                        encrypt: function(a, b, c, d) {
                            d = this.cfg.extend(d);
                            var e = a.createEncryptor(c, d);
                            return b = e.finalize(b), e = e.cfg, n.create({
                                ciphertext: b,
                                key: c,
                                iv: e.iv,
                                algorithm: a,
                                mode: e.mode,
                                padding: e.padding,
                                blockSize: a.blockSize,
                                formatter: d.format
                            })
                        },
                        decrypt: function(a, b, c, d) {
                            return d = this.cfg.extend(d), b = this._parse(b, d.format), a.createDecryptor(c, d).finalize(b.ciphertext)
                        },
                        _parse: function(a, b) {
                            return "string" == typeof a ? b.parse(a, this) : a
                        }
                    }),
                    b = (b.kdf = {}).OpenSSL = {
                        execute: function(a, b, c, d) {
                            return d || (d = f.random(8)), a = i.create({
                                keySize: b + c
                            }).compute(a, d), c = f.create(a.words.slice(b), 4 * c), a.sigBytes = 4 * b, n.create({
                                key: a,
                                iv: c,
                                salt: d
                            })
                        }
                    },
                    p = d.PasswordBasedCipher = o.extend({
                        cfg: o.cfg.extend({
                            kdf: b
                        }),
                        encrypt: function(a, b, c, d) {
                            return d = this.cfg.extend(d), c = d.kdf.execute(c, a.keySize, a.ivSize), d.iv = c.iv, a = o.encrypt.call(this, a, b, c.key, d), a.mixIn(c), a
                        },
                        decrypt: function(a, b, c, d) {
                            return d = this.cfg.extend(d), b = this._parse(b, d.format), c = d.kdf.execute(c, a.keySize, a.ivSize, b.salt), d.iv = c.iv, o.decrypt.call(this, a, b, c.key, d)
                        }
                    })
            }(),
            function() {
                for (var a = c, b = a.lib.BlockCipher, d = a.algo, e = [], f = [], g = [], h = [], i = [], j = [], k = [], l = [], m = [], n = [], o = [], p = 0; 256 > p; p++) o[p] = 128 > p ? p << 1 : p << 1 ^ 283;
                for (var q = 0, r = 0, p = 0; 256 > p; p++) {
                    var s = r ^ r << 1 ^ r << 2 ^ r << 3 ^ r << 4,
                        s = s >>> 8 ^ 255 & s ^ 99;
                    e[q] = s, f[s] = q;
                    var t = o[q],
                        u = o[t],
                        v = o[u],
                        w = 257 * o[s] ^ 16843008 * s;
                    g[q] = w << 24 | w >>> 8, h[q] = w << 16 | w >>> 16, i[q] = w << 8 | w >>> 24, j[q] = w, w = 16843009 * v ^ 65537 * u ^ 257 * t ^ 16843008 * q, k[s] = w << 24 | w >>> 8, l[s] = w << 16 | w >>> 16, m[s] = w << 8 | w >>> 24, n[s] = w, q ? (q = t ^ o[o[o[v ^ t]]], r ^= o[o[r]]) : q = r = 1
                }
                var x = [0, 1, 2, 4, 8, 16, 32, 64, 128, 27, 54],
                    d = d.AES = b.extend({
                        _doReset: function() {
                            for (var a = this._key, b = a.words, c = a.sigBytes / 4, a = 4 * ((this._nRounds = c + 6) + 1), d = this._keySchedule = [], f = 0; f < a; f++)
                                if (f < c) d[f] = b[f];
                                else {
                                    var g = d[f - 1];
                                    f % c ? 6 < c && 4 == f % c && (g = e[g >>> 24] << 24 | e[g >>> 16 & 255] << 16 | e[g >>> 8 & 255] << 8 | e[255 & g]) : (g = g << 8 | g >>> 24, g = e[g >>> 24] << 24 | e[g >>> 16 & 255] << 16 | e[g >>> 8 & 255] << 8 | e[255 & g], g ^= x[f / c | 0] << 24), d[f] = d[f - c] ^ g
                                }
                            for (b = this._invKeySchedule = [], c = 0; c < a; c++) f = a - c, g = c % 4 ? d[f] : d[f - 4], b[c] = 4 > c || 4 >= f ? g : k[e[g >>> 24]] ^ l[e[g >>> 16 & 255]] ^ m[e[g >>> 8 & 255]] ^ n[e[255 & g]]
                        },
                        encryptBlock: function(a, b) {
                            this._doCryptBlock(a, b, this._keySchedule, g, h, i, j, e)
                        },
                        decryptBlock: function(a, b) {
                            var c = a[b + 1];
                            a[b + 1] = a[b + 3], a[b + 3] = c, this._doCryptBlock(a, b, this._invKeySchedule, k, l, m, n, f), c = a[b + 1], a[b + 1] = a[b + 3], a[b + 3] = c
                        },
                        _doCryptBlock: function(a, b, c, d, e, f, g, h) {
                            for (var i = this._nRounds, j = a[b] ^ c[0], k = a[b + 1] ^ c[1], l = a[b + 2] ^ c[2], m = a[b + 3] ^ c[3], n = 4, o = 1; o < i; o++) var p = d[j >>> 24] ^ e[k >>> 16 & 255] ^ f[l >>> 8 & 255] ^ g[255 & m] ^ c[n++],
                                q = d[k >>> 24] ^ e[l >>> 16 & 255] ^ f[m >>> 8 & 255] ^ g[255 & j] ^ c[n++],
                                r = d[l >>> 24] ^ e[m >>> 16 & 255] ^ f[j >>> 8 & 255] ^ g[255 & k] ^ c[n++],
                                m = d[m >>> 24] ^ e[j >>> 16 & 255] ^ f[k >>> 8 & 255] ^ g[255 & l] ^ c[n++],
                                j = p,
                                k = q,
                                l = r;
                            p = (h[j >>> 24] << 24 | h[k >>> 16 & 255] << 16 | h[l >>> 8 & 255] << 8 | h[255 & m]) ^ c[n++], q = (h[k >>> 24] << 24 | h[l >>> 16 & 255] << 16 | h[m >>> 8 & 255] << 8 | h[255 & j]) ^ c[n++], r = (h[l >>> 24] << 24 | h[m >>> 16 & 255] << 16 | h[j >>> 8 & 255] << 8 | h[255 & k]) ^ c[n++], m = (h[m >>> 24] << 24 | h[j >>> 16 & 255] << 16 | h[k >>> 8 & 255] << 8 | h[255 & l]) ^ c[n++], a[b] = p, a[b + 1] = q, a[b + 2] = r, a[b + 3] = m
                        },
                        keySize: 8
                    });
                a.AES = b._createHelper(d)
            }(), c.mode.ECB = function() {
                var a = c.lib.BlockCipherMode.extend();
                return a.Encryptor = a.extend({
                    processBlock: function(a, b) {
                        this._cipher.encryptBlock(a, b)
                    }
                }), a.Decryptor = a.extend({
                    processBlock: function(a, b) {
                        this._cipher.decryptBlock(a, b)
                    }
                }), a
            }(), a.exports = c
    }, function(a, b) {
        a.exports = {
            name: "pubnub",
            preferGlobal: !1,
            version: "3.15.2",
            author: "PubNub <support@pubnub.com>",
            description: "Publish & Subscribe Real-time Messaging with PubNub",
            contributors: [{
                name: "Stephen Blum",
                email: "stephen@pubnub.com"
            }],
            bin: {},
            scripts: {
                test: "grunt test --force"
            },
            main: "./node.js/pubnub.js",
            browser: "./modern/dist/pubnub.js",
            repository: {
                type: "git",
                url: "git://github.com/pubnub/javascript.git"
            },
            keywords: ["cloud", "publish", "subscribe", "websockets", "comet", "bosh", "xmpp", "real-time", "messaging"],
            dependencies: {
                agentkeepalive: "~0.2",
                lodash: "^4.1.0"
            },
            noAnalyze: !1,
            devDependencies: {
                chai: "^3.5.0",
                eslint: "2.4.0",
                "eslint-config-airbnb": "^6.0.2",
                "eslint-plugin-flowtype": "^2.1.0",
                "eslint-plugin-mocha": "^2.0.0",
                "eslint-plugin-react": "^4.1.0",
                "flow-bin": "^0.22.0",
                grunt: "^0.4.5",
                "grunt-contrib-clean": "^1.0.0",
                "grunt-contrib-copy": "^0.8.2",
                "grunt-contrib-uglify": "^0.11.1",
                "grunt-env": "^0.4.4",
                "grunt-eslint": "^18.0.0",
                "grunt-flow": "^1.0.3",
                "grunt-karma": "^0.12.1",
                "grunt-mocha-istanbul": "^3.0.1",
                "grunt-text-replace": "^0.4.0",
                "grunt-webpack": "^1.0.11",
                "imports-loader": "^0.6.5",
                isparta: "^4.0.0",
                "json-loader": "^0.5.4",
                karma: "^0.13.21",
                "karma-chai": "^0.1.0",
                "karma-mocha": "^0.2.1",
                "karma-phantomjs-launcher": "^1.0.0",
                "karma-spec-reporter": "0.0.24",
                "load-grunt-tasks": "^3.4.0",
                mocha: "^2.4.5",
                nock: "^1.1.0",
                "node-uuid": "^1.4.7",
                nodeunit: "^0.9.0",
                "phantomjs-prebuilt": "^2.1.4",
                proxyquire: "^1.7.4",
                sinon: "^1.17.2",
                "uglify-js": "^2.6.1",
                underscore: "^1.7.0",
                webpack: "^1.12.13",
                "webpack-dev-server": "^1.14.1"
            },
            bundleDependencies: [],
            license: "MIT",
            engine: {
                node: ">=0.8"
            },
            files: ["core", "node.js", "modern", "CHANGELOG", "FUTURE.md", "LICENSE", "README.md"]
        }
    }, function(a, b, c) {
        function d() {
            return "x" + ++m + +new Date
        }

        function e(a, b) {
            var c = [];
            return l.each(a, function(a, d) {
                b ? a.search("-pnpres") < 0 && d.subscribed && c.push(a) : d.subscribed && c.push(a)
            }), c.sort()
        }

        function f(a, b) {
            var c = [];
            return l.each(a, function(a, d) {
                b ? a.search("-pnpres") < 0 && d.subscribed && c.push(a) : d.subscribed && c.push(a)
            }), c.sort()
        }

        function g() {
            n || (n = 1, l.each(o, function(a) {
                a()
            }))
        }

        function h(a) {
            var b = a || {
                apns: {}
            };
            return b.getPubnubMessage = function() {
                var a = {};
                if (Object.keys(b.apns).length) {
                    a.pn_apns = {
                        aps: {
                            alert: b.apns.alert,
                            badge: b.apns.badge
                        }
                    };
                    for (var c in b.apns) a.pn_apns[c] = b.apns[c];
                    var d = ["badge", "alert"];
                    for (var c in d) delete a.pn_apns[d[c]]
                }
                b.gcm && (a.pn_gcm = {
                    data: b.gcm
                });
                for (var c in b) a[c] = b[c];
                var e = ["apns", "gcm", "publish", "channel", "callback", "error"];
                for (var c in e) delete a[e[c]];
                return a
            }, b.publish = function() {
                var a = b.getPubnubMessage();
                b.pubnub && b.channel && b.pubnub.publish({
                    message: a,
                    channel: b.channel,
                    callback: b.callback,
                    error: b.error
                })
            }, b
        }

        function i(a) {
            function b(a) {
                return a || (a = {}), l.each(ta, function(b, c) {
                    b in a || (a[b] = c)
                }), a
            }

            function c(a) {
                var b = [];
                return l.each(a, function(a, c) {
                    b.push(a)
                }), b
            }

            function h(a) {
                return c(a).sort()
            }

            function i(a) {
                var b = "",
                    c = h(a);
                for (var d in c) {
                    var e = c[d];
                    b += e + "=" + l.pamEncode(a[e]), d != c.length - 1 && (b += "&")
                }
                return b
            }

            function j(a, b, c) {
                var d = !1;
                if ("undefined" == typeof a) return b;
                if ("number" == typeof a) d = !(a > v || 0 == a);
                else {
                    if ("boolean" == typeof a) return a ? w : 0;
                    d = !0
                }
                return d ? (c && c("Presence Heartbeat value invalid. Valid range ( x > " + v + " or x = 0). Current Value : " + (b || v)), b || v) : a
            }

            function k(a, b) {
                return Fa.encrypt(a, b || ya) || a
            }

            function m(a, b) {
                return Fa.decrypt(a, b || ya) || Fa.decrypt(a, ya) || a
            }

            function r() {
                return clearTimeout(ma), !oa || oa >= 500 || oa < 1 || !e(ja, !0).length && !f(ka, !0).length ? void(pa = !1) : (pa = !0, void Ga.presence_heartbeat({
                    callback: function(a) {
                        ma = l.timeout(r, oa * u)
                    },
                    error: function(a) {
                        ua && ua("Presence Heartbeat unable to reach Pubnub servers." + JSON.stringify(a)), ma = l.timeout(r, oa * u)
                    }
                }))
            }

            function z() {
                !pa && r()
            }

            function A(a) {
                if (qa) {
                    if (!aa.length) return
                } else {
                    if (a && (aa.sending = 0), aa.sending || !aa.length) return;
                    aa.sending = 1
                }
                sa(aa.shift())
            }

            function B(a) {
                var b = 0;
                return l.each(f(ka), function(c) {
                    var d = ka[c];
                    d && (b++, (a || function() {})(d))
                }), b
            }

            function C(a) {
                var b = 0;
                return l.each(e(ja), function(c) {
                    var d = ja[c];
                    d && (b++, (a || function() {})(d))
                }), b
            }

            function D(a, b, c) {
                if ("object" == typeof a) {
                    if (a.error) {
                        var d = {};
                        return a.message && (d.message = a.message), a.payload && (d.payload = a.payload), void(c && c(d))
                    }
                    if (a.payload) return void(a.next_page ? b && b(a.payload, a.next_page) : b && b(a.payload))
                }
                b && b(a)
            }

            function E(a, b) {
                if ("object" == typeof a && a.error) {
                    var c = {};
                    return a.message && (c.message = a.message), a.payload && (c.payload = a.payload), void(b && b(c))
                }
                b && b(a)
            }

            function F(a, c, d, e) {
                var c = a.callback || c,
                    f = a.error || ua,
                    g = wa();
                e = e || {}, e.auth || (e.auth = a.auth_key || U);
                var h = [Z, "v1", "channel-registration", "sub-key", T];
                h.push.apply(h, d), g && (e.callback = g), sa({
                    callback: g,
                    data: b(e),
                    success: function(a) {
                        D(a, c, f)
                    },
                    fail: function(a) {
                        E(a, f)
                    },
                    url: h
                })
            }

            function G() {
                va() || I(1, {
                    error: "Offline. Please check your network settings."
                }), L && clearTimeout(L), L = l.timeout(G, u)
            }

            function H() {
                Q && Ga.time(function(a) {
                    K(function() {}, a), a || I(1, {
                        error: "Heartbeat failed to connect to Pubnub Servers.Please check your network settings."
                    }), M && clearTimeout(M), M = l.timeout(H, P)
                })
            }

            function I(a, b) {
                fa && fa(a, b), fa = null, clearTimeout(L), clearTimeout(M)
            }

            function J(a) {
                var b = l.rnow() - ca;
                return b - a / 1e4
            }

            function K(a, b) {
                function c(b) {
                    if (b) {
                        var c = b / 1e4,
                            e = (l.rnow() - d) / 2;
                        ca = l.rnow() - (c + e), a && a(ca)
                    }
                }
                var d = l.rnow();
                b && c(b) || Ga.time(c)
            }
            var L, M, N = +a.windowing || q,
                O = (+a.timeout || s) * u,
                P = (+a.keepalive || t) * u,
                Q = a.timecheck || 0,
                R = a.noleave || 0,
                S = a.publish_key,
                T = a.subscribe_key,
                U = a.auth_key || "",
                V = a.secret_key || "",
                W = a.hmac_SHA256,
                X = a.ssl ? "s" : "",
                Y = "https" + X + "://" + (a.origin || "pubsub.pubnub.com"),
                Z = y(Y),
                $ = y(Y),
                _ = function() {},
                aa = [],
                ba = !0,
                ca = 0,
                da = 0,
                ea = 0,
                fa = 0,
                ga = a.restore || 0,
                ha = 0,
                ia = !1,
                ja = {},
                ka = {},
                la = {},
                ma = null,
                na = j(a.heartbeat || a.pnexpires || 0, a.error),
                oa = a.heartbeat_interval || na / 2 - 1,
                pa = !1,
                qa = a.no_wait_for_pending,
                ra = a["compatible_3.5"] || !1,
                sa = a.xdr,
                ta = a.params || {},
                ua = a.error || function() {},
                va = a._is_online || function() {
                    return 1
                },
                wa = a.jsonp_cb || function() {
                    return 0
                },
                xa = a.db || {
                    get: function() {},
                    set: function() {}
                },
                ya = a.cipher_key,
                za = a.uuid || !a.unique_uuid && xa && xa.get(T + "uuid") || "",
                Aa = a.instance_id || !1,
                Ba = "",
                Ca = a.shutdown,
                Da = "undefined" == typeof a.use_send_beacon || a.use_send_beacon,
                Ea = Da ? a.sendBeacon : null;
            2 === na && (oa = 1);
            var Fa = a.crypto_obj || {
                    encrypt: function(a, b) {
                        return a
                    },
                    decrypt: function(a, b) {
                        return a
                    }
                },
                Ga = {
                    LEAVE: function(a, c, d, e, f) {
                        var g, h, i = {
                                uuid: za,
                                auth: d || U
                            },
                            j = y(Y),
                            e = e || function() {},
                            k = f || function() {},
                            m = wa();
                        if (a.indexOf(p) > 0) return !0;
                        if (ra) {
                            if (!X) return !1;
                            if ("0" == m) return !1
                        }
                        if (R) return !1;
                        if ("0" != m && (i.callback = m), Aa && (i.instanceid = Ba), g = [j, "v2", "presence", "sub_key", T, "channel", l.encode(a), "leave"], h = b(i), Ea) {
                            var n = l.buildURL(g, h);
                            if (Ea(n)) return e && e({
                                status: 200,
                                action: "leave",
                                message: "OK",
                                service: "Presence"
                            }), !0
                        }
                        return sa({
                            blocking: c || X,
                            callback: m,
                            data: h,
                            success: function(a) {
                                D(a, e, k)
                            },
                            fail: function(a) {
                                E(a, k)
                            },
                            url: g
                        }), !0
                    },
                    LEAVE_GROUP: function(a, c, d, e, f) {
                        var g, h, i = {
                                uuid: za,
                                auth: d || U
                            },
                            j = y(Y),
                            e = e || function() {},
                            k = f || function() {},
                            m = wa();
                        if (a.indexOf(p) > 0) return !0;
                        if (ra) {
                            if (!X) return !1;
                            if ("0" == m) return !1
                        }
                        if (R) return !1;
                        if ("0" != m && (i.callback = m), a && a.length > 0 && (i["channel-group"] = a), Aa && (i.instanceid = Ba), g = [j, "v2", "presence", "sub_key", T, "channel", l.encode(","), "leave"], h = b(i), Ea) {
                            var n = l.buildURL(g, h);
                            if (Ea(n)) return e && e({
                                status: 200,
                                action: "leave",
                                message: "OK",
                                service: "Presence"
                            }), !0
                        }
                        return sa({
                            blocking: c || X,
                            callback: m,
                            data: h,
                            success: function(a) {
                                D(a, e, k)
                            },
                            fail: function(a) {
                                E(a, k)
                            },
                            url: g
                        }), !0
                    },
                    set_resumed: function(a) {
                        ia = a
                    },
                    get_cipher_key: function() {
                        return ya
                    },
                    set_cipher_key: function(a) {
                        ya = a
                    },
                    raw_encrypt: function(a, b) {
                        return k(a, b)
                    },
                    raw_decrypt: function(a, b) {
                        return m(a, b)
                    },
                    get_heartbeat: function() {
                        return na
                    },
                    set_heartbeat: function(a, b) {
                        na = j(a, na, ua), oa = b || na / 2 - 1, 2 == na && (oa = 1), _(), r()
                    },
                    get_heartbeat_interval: function() {
                        return oa
                    },
                    set_heartbeat_interval: function(a) {
                        oa = a, r()
                    },
                    get_version: function() {
                        return x
                    },
                    getGcmMessageObject: function(a) {
                        return {
                            data: a
                        }
                    },
                    getApnsMessageObject: function(a) {
                        var b = {
                            aps: {
                                badge: 1,
                                alert: ""
                            }
                        };
                        for (var c in a) c[b] = a[c];
                        return b
                    },
                    _add_param: function(a, b) {
                        ta[a] = b
                    },
                    channel_group: function(a, b) {
                        var c, d, e = a.channel_group,
                            b = b || a.callback,
                            f = a.channels || a.channel,
                            g = a.cloak,
                            h = [],
                            i = {},
                            j = a.mode || "add";
                        if (e) {
                            var k = e.split(":");
                            k.length > 1 ? (c = "*" === k[0] ? null : k[0], d = k[1]) : d = k[0]
                        }
                        c && h.push("namespace") && h.push(l.encode(c)), h.push("channel-group"), d && "*" !== d && h.push(d), f ? (l.isArray(f) && (f = f.join(",")), i[j] = f, i.cloak = ba ? "true" : "false") : "remove" === j && h.push("remove"), "undefined" != typeof g && (i.cloak = g ? "true" : "false"), F(a, b, h, i)
                    },
                    channel_group_list_groups: function(a, b) {
                        var c;
                        c = a.namespace || a.ns || a.channel_group || null, c && (a.channel_group = c + ":*"), Ga.channel_group(a, b)
                    },
                    channel_group_list_channels: function(a, b) {
                        return a.channel_group ? void Ga.channel_group(a, b) : ua("Missing Channel Group")
                    },
                    channel_group_remove_channel: function(a, b) {
                        return a.channel_group ? a.channel || a.channels ? (a.mode = "remove", void Ga.channel_group(a, b)) : ua("Missing Channel") : ua("Missing Channel Group")
                    },
                    channel_group_remove_group: function(a, b) {
                        return a.channel_group ? a.channel ? ua("Use channel_group_remove_channel if you want to remove a channel from a group.") : (a.mode = "remove", void Ga.channel_group(a, b)) : ua("Missing Channel Group")
                    },
                    channel_group_add_channel: function(a, b) {
                        return a.channel_group ? a.channel || a.channels ? void Ga.channel_group(a, b) : ua("Missing Channel") : ua("Missing Channel Group")
                    },
                    channel_group_cloak: function(a, b) {
                        return "undefined" == typeof a.cloak ? void b(ba) : (ba = a.cloak,
                            void Ga.channel_group(a, b))
                    },
                    channel_group_list_namespaces: function(a, b) {
                        var c = ["namespace"];
                        F(a, b, c)
                    },
                    channel_group_remove_namespace: function(a, b) {
                        var c = ["namespace", a.namespace, "remove"];
                        F(a, b, c)
                    },
                    history: function(a, c) {
                        var c = a.callback || c,
                            d = a.count || a.limit || 100,
                            e = a.reverse || "false",
                            f = a.error || function() {},
                            g = a.auth_key || U,
                            h = a.cipher_key,
                            i = a.channel,
                            j = a.channel_group,
                            k = a.start,
                            n = a.end,
                            o = a.include_token,
                            p = a.string_message_token || !1,
                            q = {},
                            r = wa();
                        return i || j ? c ? T ? (q.stringtoken = "true", q.count = d, q.reverse = e, q.auth = g, j && (q["channel-group"] = j, i || (i = ",")), r && (q.callback = r), k && (q.start = k), n && (q.end = n), o && (q.include_token = "true"), p && (q.string_message_token = "true"), void sa({
                            callback: r,
                            data: b(q),
                            success: function(a) {
                                if ("object" == typeof a && a.error) return void f({
                                    message: a.message,
                                    payload: a.payload
                                });
                                for (var b = a[0], d = [], e = 0; e < b.length; e++)
                                    if (o) {
                                        var g = m(b[e].message, h),
                                            i = b[e].timetoken;
                                        try {
                                            d.push({
                                                message: JSON.parse(g),
                                                timetoken: i
                                            })
                                        } catch (j) {
                                            d.push({
                                                message: g,
                                                timetoken: i
                                            })
                                        }
                                    } else {
                                        var g = m(b[e], h);
                                        try {
                                            d.push(JSON.parse(g))
                                        } catch (j) {
                                            d.push(g)
                                        }
                                    }
                                c([d, a[1], a[2]])
                            },
                            fail: function(a) {
                                E(a, f)
                            },
                            url: [Z, "v2", "history", "sub-key", T, "channel", l.encode(i)]
                        })) : ua("Missing Subscribe Key") : ua("Missing Callback") : ua("Missing Channel")
                    },
                    replay: function(a, c) {
                        var d, c = c || a.callback || function() {},
                            e = a.auth_key || U,
                            f = a.source,
                            g = a.destination,
                            h = a.error || a.error || function() {},
                            i = a.stop,
                            j = a.start,
                            k = a.end,
                            l = a.reverse,
                            m = a.limit,
                            n = wa(),
                            o = {};
                        return f ? g ? S ? T ? ("0" != n && (o.callback = n), i && (o.stop = "all"), l && (o.reverse = "true"), j && (o.start = j), k && (o.end = k), m && (o.count = m), o.auth = e, d = [Z, "v1", "replay", S, T, f, g], void sa({
                            callback: n,
                            success: function(a) {
                                D(a, c, h)
                            },
                            fail: function() {
                                c([0, "Disconnected"])
                            },
                            url: d,
                            data: b(o)
                        })) : ua("Missing Subscribe Key") : ua("Missing Publish Key") : ua("Missing Destination Channel") : ua("Missing Source Channel")
                    },
                    auth: function(a) {
                        U = a, _()
                    },
                    time: function(a) {
                        var c = wa(),
                            d = {
                                uuid: za,
                                auth: U
                            };
                        Aa && (d.instanceid = Ba), sa({
                            callback: c,
                            data: b(d),
                            url: [Z, "time", c],
                            success: function(b) {
                                a(b[0])
                            },
                            fail: function() {
                                a(0)
                            }
                        })
                    },
                    publish: function(a, c) {
                        var d = a.message;
                        if (!d) return ua("Missing Message");
                        var e, f, c = c || a.callback || d.callback || a.success || function() {},
                            g = a.channel || d.channel,
                            h = a.meta || a.metadata,
                            i = a.auth_key || U,
                            j = a.cipher_key,
                            m = a.error || d.error || function() {},
                            n = a.post || !1,
                            o = !("store_in_history" in a) || a.store_in_history,
                            p = !("replicate" in a) || a.replicate,
                            q = wa(),
                            r = "push";
                        return a.prepend && (r = "unshift"), g ? S ? T ? (d.getPubnubMessage && (d = d.getPubnubMessage()), d = JSON.stringify(k(d, j)), f = [Z, "publish", S, T, 0, l.encode(g), q, l.encode(d)], e = {
                            uuid: za,
                            auth: i
                        }, h && "object" == typeof h && (e.meta = JSON.stringify(h)), o || (e.store = "0"), p || (e.norep = "true"), Aa && (e.instanceid = Ba), aa[r]({
                            callback: q,
                            url: f,
                            data: b(e),
                            fail: function(a) {
                                E(a, m), A(1)
                            },
                            success: function(a) {
                                D(a, c, m), A(1)
                            },
                            mode: n ? "POST" : "GET"
                        }), void A()) : ua("Missing Subscribe Key") : ua("Missing Publish Key") : ua("Missing Channel")
                    },
                    fire: function(a, b) {
                        a.store_in_history = !1, a.replicate = !1, Ga.publish(a, b)
                    },
                    unsubscribe: function(a, b) {
                        var c = a.channel,
                            d = a.channel_group,
                            e = a.auth_key || U,
                            b = b || a.callback || function() {},
                            f = a.error || function() {};
                        if (!c && !d) return ua("Missing Channel or Channel Group");
                        if (!T) return ua("Missing Subscribe Key");
                        if (c) {
                            var g = l.isArray(c) ? c : ("" + c).split(","),
                                h = [],
                                i = [];
                            if (l.each(g, function(a) {
                                    ja[a] && h.push(a)
                                }), 0 == h.length) return void b({
                                action: "leave"
                            });
                            l.each(h, function(a) {
                                i.push(a + p)
                            }), l.each(h.concat(i), function(a) {
                                a in ja && delete ja[a], a in la && delete la[a]
                            }), 0 === ja.length && 0 === ka.length && (ha = 0);
                            var j = !0;
                            n && (j = Ga.LEAVE(h.join(","), 0, e, b, f)), j || b({
                                action: "leave"
                            })
                        }
                        if (d) {
                            var k = l.isArray(d) ? d : ("" + d).split(","),
                                m = [],
                                o = [];
                            if (l.each(k, function(a) {
                                    ka[a] && m.push(a)
                                }), 0 == m.length) return void b({
                                action: "leave"
                            });
                            l.each(m, function(a) {
                                o.push(a + p)
                            }), l.each(m.concat(o), function(a) {
                                a in ka && delete ka[a], a in la && delete la[a]
                            }), 0 === ja.length && 0 === ka.length && (ha = 0);
                            var j = !0;
                            n && (j = Ga.LEAVE_GROUP(m.join(","), 0, e, b, f)), j || b({
                                action: "leave"
                            })
                        }
                        _()
                    },
                    subscribe: function(a, c) {
                        function d(a) {
                            a ? l.timeout(_, D) : (Z = y(Y, 1), $ = y(Y, 1), l.timeout(function() {
                                Ga.time(d)
                            }, u)), C(function(b) {
                                return a && b.disconnected ? (b.disconnected = 0, b.reconnect(b.name)) : void(a || b.disconnected || (b.disconnected = 1, b.disconnect(b.name)))
                            }), B(function(b) {
                                return a && b.disconnected ? (b.disconnected = 0, b.reconnect(b.name)) : void(a || b.disconnected || (b.disconnected = 1, b.disconnect(b.name)))
                            })
                        }

                        function g() {
                            var a = wa(),
                                c = e(ja).join(","),
                                h = f(ka).join(",");
                            if (c || h) {
                                c || (c = ","), I();
                                var i = b({
                                    uuid: za,
                                    auth: U
                                });
                                h && (i["channel-group"] = h);
                                var j = JSON.stringify(la);
                                j.length > 2 && (i.state = JSON.stringify(la)), na && (i.heartbeat = na), Aa && (i.instanceid = Ba), z(), fa = sa({
                                    timeout: A,
                                    callback: a,
                                    fail: function(a) {
                                        a && a.error && a.service ? (E(a, r), d(!1)) : Ga.time(function(b) {
                                            !b && E(a, r), d(b)
                                        })
                                    },
                                    data: b(i),
                                    url: [$, "subscribe", T, l.encode(c), a, ha],
                                    success: function(a) {
                                        if (!a || "object" == typeof a && "error" in a && a.error) return r(a), l.timeout(_, u);
                                        if (s(a[1]), ha = !ha && ga && xa.get(T) || a[1], C(function(a) {
                                                a.connected || (a.connected = 1, a.connect(a.name))
                                            }), B(function(a) {
                                                a.connected || (a.connected = 1, a.connect(a.name))
                                            }), ia && !ga) return ha = 0, ia = !1, xa.set(T, 0), void l.timeout(g, D);
                                        w && (ha = 1e4, w = 0), xa.set(T, a[1]);
                                        var b = function() {
                                                var b = "",
                                                    c = "";
                                                a.length > 3 ? (b = a[3], c = a[2]) : b = a.length > 2 ? a[2] : l.map(e(ja), function(b) {
                                                    return l.map(Array(a[0].length).join(",").split(","), function() {
                                                        return b
                                                    })
                                                }).join(",");
                                                var d = b.split(","),
                                                    f = c ? c.split(",") : [];
                                                return function() {
                                                    var a = d.shift() || ea,
                                                        b = f.shift(),
                                                        c = {};
                                                    b ? (a && a.indexOf("-pnpres") >= 0 && b.indexOf("-pnpres") < 0 && (b += "-pnpres"), c = ka[b] || ja[b] || {
                                                        callback: function() {}
                                                    }) : c = ja[a];
                                                    var e = [c.callback || da, a.split(p)[0]];
                                                    return b && e.push(b.split(p)[0]), e
                                                }
                                            }(),
                                            c = J(+a[1]);
                                        l.each(a[0], function(d) {
                                            var e = b(),
                                                f = m(d, ja[e[1]] ? ja[e[1]].cipher_key : null);
                                            e[0] && e[0](f, a, e[2] || e[1], c, e[1])
                                        }), l.timeout(g, D)
                                    }
                                })
                            }
                        }
                        var h = a.channel,
                            i = a.channel_group,
                            c = c || a.callback,
                            c = c || a.message,
                            j = a.connect || function() {},
                            k = a.reconnect || function() {},
                            q = a.disconnect || function() {},
                            r = a.error || r || function() {},
                            s = a.idle || function() {},
                            t = a.presence || 0,
                            v = a.noheresync || 0,
                            w = a.backfill || 0,
                            x = a.timetoken || 0,
                            A = a.timeout || O,
                            D = a.windowing || N,
                            F = a.state,
                            G = a.heartbeat || a.pnexpires,
                            H = a.heartbeat_interval,
                            K = a.restore || ga;
                        return U = a.auth_key || U, ga = K, ha = x, h || i ? c ? T ? ((G || 0 === G || H || 0 === H) && Ga.set_heartbeat(G, H), h && l.each((h.join ? h.join(",") : "" + h).split(","), function(d) {
                            var e = ja[d] || {};
                            ja[ea = d] = {
                                name: d,
                                connected: e.connected,
                                disconnected: e.disconnected,
                                subscribed: 1,
                                callback: da = c,
                                cipher_key: a.cipher_key,
                                connect: j,
                                disconnect: q,
                                reconnect: k
                            }, F && (d in F ? la[d] = F[d] : la[d] = F), t && (Ga.subscribe({
                                channel: d + p,
                                callback: t,
                                restore: K
                            }), e.subscribed || v || Ga.here_now({
                                channel: d,
                                data: b({
                                    uuid: za,
                                    auth: U
                                }),
                                callback: function(a) {
                                    l.each("uuids" in a ? a.uuids : [], function(b) {
                                        t({
                                            action: "join",
                                            uuid: b,
                                            timestamp: Math.floor(l.rnow() / 1e3),
                                            occupancy: a.occupancy || 1
                                        }, a, d)
                                    })
                                }
                            }))
                        }), i && l.each((i.join ? i.join(",") : "" + i).split(","), function(d) {
                            var e = ka[d] || {};
                            ka[d] = {
                                name: d,
                                connected: e.connected,
                                disconnected: e.disconnected,
                                subscribed: 1,
                                callback: da = c,
                                cipher_key: a.cipher_key,
                                connect: j,
                                disconnect: q,
                                reconnect: k
                            }, t && (Ga.subscribe({
                                channel_group: d + p,
                                callback: t,
                                restore: K,
                                auth_key: U
                            }), e.subscribed || v || Ga.here_now({
                                channel_group: d,
                                data: b({
                                    uuid: za,
                                    auth: U
                                }),
                                callback: function(a) {
                                    l.each("uuids" in a ? a.uuids : [], function(b) {
                                        t({
                                            action: "join",
                                            uuid: b,
                                            timestamp: Math.floor(l.rnow() / 1e3),
                                            occupancy: a.occupancy || 1
                                        }, a, d)
                                    })
                                }
                            }))
                        }), _ = function() {
                            I(), l.timeout(g, D)
                        }, n ? void _() : o.push(_)) : ua("Missing Subscribe Key") : ua("Missing Callback") : ua("Missing Channel")
                    },
                    here_now: function(a, c) {
                        var c = a.callback || c,
                            d = a.debug,
                            e = a.error || function() {},
                            f = a.auth_key || U,
                            g = a.channel,
                            h = a.channel_group,
                            i = wa(),
                            j = !("uuids" in a) || a.uuids,
                            k = a.state,
                            m = {
                                uuid: za,
                                auth: f
                            };
                        if (j || (m.disable_uuids = 1), k && (m.state = 1), !c) return ua("Missing Callback");
                        if (!T) return ua("Missing Subscribe Key");
                        var n = [Z, "v2", "presence", "sub_key", T];
                        g && n.push("channel") && n.push(l.encode(g)), "0" != i && (m.callback = i), h && (m["channel-group"] = h, !g && n.push("channel") && n.push(",")), Aa && (m.instanceid = Ba), sa({
                            callback: i,
                            data: b(m),
                            success: function(a) {
                                D(a, c, e)
                            },
                            fail: function(a) {
                                E(a, e)
                            },
                            debug: d,
                            url: n
                        })
                    },
                    where_now: function(a, c) {
                        var c = a.callback || c,
                            d = a.error || function() {},
                            e = a.auth_key || U,
                            f = wa(),
                            g = a.uuid || za,
                            h = {
                                auth: e
                            };
                        return c ? T ? ("0" != f && (h.callback = f), Aa && (h.instanceid = Ba), void sa({
                            callback: f,
                            data: b(h),
                            success: function(a) {
                                D(a, c, d)
                            },
                            fail: function(a) {
                                E(a, d)
                            },
                            url: [Z, "v2", "presence", "sub_key", T, "uuid", l.encode(g)]
                        })) : ua("Missing Subscribe Key") : ua("Missing Callback")
                    },
                    state: function(a, c) {
                        var d, c = a.callback || c || function(a) {},
                            e = a.error || function() {},
                            f = a.auth_key || U,
                            g = wa(),
                            h = a.state,
                            i = a.uuid || za,
                            j = a.channel,
                            k = a.channel_group,
                            m = b({
                                auth: f
                            });
                        return T ? i ? j || k ? ("0" != g && (m.callback = g), "undefined" != typeof j && ja[j] && ja[j].subscribed && h && (la[j] = h), "undefined" != typeof k && ka[k] && ka[k].subscribed && (h && (la[k] = h), m["channel-group"] = k, j || (j = ",")), m.state = JSON.stringify(h), Aa && (m.instanceid = Ba), d = h ? [Z, "v2", "presence", "sub-key", T, "channel", j, "uuid", i, "data"] : [Z, "v2", "presence", "sub-key", T, "channel", j, "uuid", l.encode(i)], void sa({
                            callback: g,
                            data: b(m),
                            success: function(a) {
                                D(a, c, e)
                            },
                            fail: function(a) {
                                E(a, e)
                            },
                            url: d
                        })) : ua("Missing Channel") : ua("Missing UUID") : ua("Missing Subscribe Key")
                    },
                    grant: function(a, c) {
                        var c = a.callback || c,
                            d = a.error || function() {},
                            e = a.channel || a.channels,
                            f = a.channel_group,
                            g = wa(),
                            h = a.ttl,
                            j = a.read ? "1" : "0",
                            k = a.write ? "1" : "0",
                            m = a.manage ? "1" : "0",
                            n = a.auth_key || a.auth_keys;
                        if (!c) return ua("Missing Callback");
                        if (!T) return ua("Missing Subscribe Key");
                        if (!S) return ua("Missing Publish Key");
                        if (!V) return ua("Missing Secret Key");
                        var o = Math.floor((new Date).getTime() / 1e3),
                            p = T + "\n" + S + "\ngrant\n",
                            q = {
                                w: k,
                                r: j,
                                timestamp: o
                            };
                        a.manage && (q.m = m), l.isArray(e) && (e = e.join(",")), l.isArray(n) && (n = n.join(",")), "undefined" != typeof e && null != e && e.length > 0 && (q.channel = e), "undefined" != typeof f && null != f && f.length > 0 && (q["channel-group"] = f), "0" != g && (q.callback = g), (h || 0 === h) && (q.ttl = h), n && (q.auth = n), q = b(q), n || delete q.auth, p += i(q);
                        var r = W(p, V);
                        r = r.replace(/\+/g, "-"), r = r.replace(/\//g, "_"), q.signature = r, sa({
                            callback: g,
                            data: q,
                            success: function(a) {
                                D(a, c, d)
                            },
                            fail: function(a) {
                                E(a, d)
                            },
                            url: [Z, "v1", "auth", "grant", "sub-key", T]
                        })
                    },
                    mobile_gw_provision: function(a) {
                        var b, c, d = a.callback || function() {},
                            e = a.auth_key || U,
                            f = a.error || function() {},
                            g = wa(),
                            h = a.channel,
                            i = a.op,
                            j = a.gw_type,
                            k = a.device_id;
                        return k ? j ? i ? h ? T ? (c = [Z, "v1/push/sub-key", T, "devices", k], b = {
                            uuid: za,
                            auth: e,
                            type: j
                        }, "add" == i ? b.add = h : "remove" == i && (b.remove = h), Aa && (b.instanceid = Ba), void sa({
                            callback: g,
                            data: b,
                            success: function(a) {
                                D(a, d, f)
                            },
                            fail: function(a) {
                                E(a, f)
                            },
                            url: c
                        })) : ua("Missing Subscribe Key") : ua("Missing gw destination Channel (channel)") : ua("Missing GW Operation (op: add or remove)") : ua("Missing GW Type (gw_type: gcm or apns)") : ua("Missing Device ID (device_id)")
                    },
                    audit: function(a, c) {
                        var c = a.callback || c,
                            d = a.error || function() {},
                            e = a.channel,
                            f = a.channel_group,
                            g = a.auth_key,
                            h = wa();
                        if (!c) return ua("Missing Callback");
                        if (!T) return ua("Missing Subscribe Key");
                        if (!S) return ua("Missing Publish Key");
                        if (!V) return ua("Missing Secret Key");
                        var j = Math.floor((new Date).getTime() / 1e3),
                            k = T + "\n" + S + "\naudit\n",
                            l = {
                                timestamp: j
                            };
                        "0" != h && (l.callback = h), "undefined" != typeof e && null != e && e.length > 0 && (l.channel = e), "undefined" != typeof f && null != f && f.length > 0 && (l["channel-group"] = f), g && (l.auth = g), l = b(l), g || delete l.auth, k += i(l);
                        var m = W(k, V);
                        m = m.replace(/\+/g, "-"), m = m.replace(/\//g, "_"), l.signature = m, sa({
                            callback: h,
                            data: l,
                            success: function(a) {
                                D(a, c, d)
                            },
                            fail: function(a) {
                                E(a, d)
                            },
                            url: [Z, "v1", "auth", "audit", "sub-key", T]
                        })
                    },
                    revoke: function(a, b) {
                        a.read = !1, a.write = !1, Ga.grant(a, b)
                    },
                    set_uuid: function(a) {
                        za = a, _()
                    },
                    get_uuid: function() {
                        return za
                    },
                    isArray: function(a) {
                        return l.isArray(a)
                    },
                    get_subscribed_channels: function() {
                        return e(ja, !0)
                    },
                    presence_heartbeat: function(a) {
                        var c = a.callback || function() {},
                            d = a.error || function() {},
                            g = wa(),
                            h = {
                                uuid: za,
                                auth: U
                            },
                            i = JSON.stringify(la);
                        i.length > 2 && (h.state = JSON.stringify(la)), na > 0 && na < 320 && (h.heartbeat = na), "0" != g && (h.callback = g);
                        var j = l.encode(e(ja, !0).join(",")),
                            k = f(ka, !0).join(",");
                        j || (j = ","), k && (h["channel-group"] = k), Aa && (h.instanceid = Ba), sa({
                            callback: g,
                            data: b(h),
                            url: [Z, "v2", "presence", "sub-key", T, "channel", j, "heartbeat"],
                            success: function(a) {
                                D(a, c, d)
                            },
                            fail: function(a) {
                                E(a, d)
                            }
                        })
                    },
                    stop_timers: function() {
                        clearTimeout(L), clearTimeout(M), clearTimeout(ma)
                    },
                    shutdown: function() {
                        Ga.stop_timers(), Ca && Ca()
                    },
                    xdr: sa,
                    ready: g,
                    db: xa,
                    uuid: l.generateUUID,
                    map: l.map,
                    each: l.each,
                    "each-channel": C,
                    grep: l.grep,
                    offline: function() {
                        I(1, {
                            message: "Offline. Please check your network settings."
                        })
                    },
                    supplant: l.supplant,
                    now: l.rnow,
                    unique: d,
                    updater: l.updater
                };
            return za || (za = Ga.uuid()), Ba || (Ba = Ga.uuid()), xa.set(T + "uuid", za), L = l.timeout(G, u), M = l.timeout(H, P), ma = l.timeout(z, (oa - 3) * u), K(), Ga
        }
        var j = c(4),
            k = c(6),
            l = c(7),
            m = 1,
            n = !1,
            o = [],
            p = "-pnpres",
            q = 10,
            r = 15e3,
            s = 310,
            t = 60,
            u = 1e3,
            v = 5,
            w = 30,
            x = j.version,
            y = function() {
                var a = 20,
                    b = Math.floor(Math.random() * a);
                return function(c, d) {
                    return c.indexOf("pubsub.") > 0 && c.replace("pubsub", "ps" + (d ? l.generateUUID().split("-")[0] : ++b < a ? b : b = 1)) || c
                }
            }();
        a.exports = {
            PN_API: i,
            unique: d,
            PNmessage: h,
            DEF_TIMEOUT: r,
            timeout: l.timeout,
            build_url: l.buildURL,
            each: l.each,
            uuid: l.generateUUID,
            URLBIT: k.URLBIT,
            grep: l.grep,
            supplant: l.supplant,
            now: l.rnow,
            updater: l.updater,
            map: l.map
        }
    }, function(a, b) {
        a.exports = {
            PARAMSBIT: "&",
            URLBIT: "/"
        }
    }, function(a, b, c) {
        function d() {
            return +new Date
        }

        function e(a) {
            return !!a && "string" != typeof a && (Array.isArray && Array.isArray(a) || "number" == typeof a.length)
        }

        function f(a, b) {
            if (a && b)
                if (e(a))
                    for (var c = 0, d = a.length; c < d;) b.call(a[c], a[c], c++);
                else
                    for (var c in a) a.hasOwnProperty && a.hasOwnProperty(c) && b.call(a[c], c, a[c])
        }

        function g(a) {
            return encodeURIComponent(a)
        }

        function h(a, b) {
            var c = a.join(p.URLBIT),
                d = [];
            return b ? (f(b, function(a, b) {
                var c = "object" == typeof b ? JSON.stringify(b) : b;
                "undefined" != typeof b && null !== b && g(c).length > 0 && d.push(a + "=" + g(c))
            }), c += "?" + d.join(p.PARAMSBIT)) : c
        }

        function i(a, b) {
            var c, e = 0,
                f = function() {
                    e + b > d() ? (clearTimeout(c), c = setTimeout(f, b)) : (e = d(), a())
                };
            return f
        }

        function j(a, b) {
            var c = [];
            return f(a || [], function(a) {
                b(a) && c.push(a)
            }), c
        }

        function k(a, b) {
            return a.replace(q, function(a, c) {
                return b[c] || a
            })
        }

        function l(a, b) {
            if ("undefined" != typeof setTimeout) return setTimeout(a, b)
        }

        function m(a) {
            var b = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(a) {
                var b = 16 * Math.random() | 0,
                    c = "x" === a ? b : 3 & b | 8;
                return c.toString(16)
            });
            return a && a(b), b
        }

        function n(a, b) {
            var c = [];
            return f(a || [], function(a, d) {
                c.push(b(a, d))
            }), c
        }

        function o(a) {
            return encodeURIComponent(a).replace(/[!'()*~]/g, function(a) {
                return "%" + a.charCodeAt(0).toString(16).toUpperCase()
            })
        }
        var p = c(6),
            q = /{([\w\-]+)}/g;
        a.exports = {
            buildURL: h,
            encode: g,
            each: f,
            updater: i,
            rnow: d,
            isArray: e,
            map: n,
            pamEncode: o,
            generateUUID: m,
            timeout: l,
            supplant: k,
            grep: j
        }
    }, function(a, b) {
        var c = function(a, b) {
            if (!(this instanceof c)) return new c(a, b);
            var d = this,
                a = d.url = a || "",
                e = (d.protocol = b || "Sec-WebSocket-Protocol", a.split("/")),
                f = {
                    ssl: "wss:" === e[0],
                    origin: e[2],
                    publish_key: e[3],
                    subscribe_key: e[4],
                    channel: e[5]
                };
            return d.CONNECTING = 0, d.OPEN = 1, d.CLOSING = 2, d.CLOSED = 3, d.CLOSE_NORMAL = 1e3, d.CLOSE_GOING_AWAY = 1001, d.CLOSE_PROTOCOL_ERROR = 1002, d.CLOSE_UNSUPPORTED = 1003, d.CLOSE_TOO_LARGE = 1004, d.CLOSE_NO_STATUS = 1005, d.CLOSE_ABNORMAL = 1006, d.onclose = d.onerror = d.onmessage = d.onopen = d.onsend = function() {}, d.binaryType = "", d.extensions = "", d.bufferedAmount = 0, d.trasnmitting = !1, d.buffer = [], d.readyState = d.CONNECTING, a ? (d.pubnub = PUBNUB.init(f), d.pubnub.setup = f, d.setup = f, void d.pubnub.subscribe({
                restore: !1,
                channel: f.channel,
                disconnect: d.onerror,
                reconnect: d.onopen,
                error: function() {
                    d.onclose({
                        code: d.CLOSE_ABNORMAL,
                        reason: "Missing URL",
                        wasClean: !1
                    })
                },
                callback: function(a) {
                    d.onmessage({
                        data: a
                    })
                },
                connect: function() {
                    d.readyState = d.OPEN, d.onopen()
                }
            })) : (d.readyState = d.CLOSED, d.onclose({
                code: d.CLOSE_ABNORMAL,
                reason: "Missing URL",
                wasClean: !0
            }), d)
        };
        c.prototype.send = function(a) {
            var b = this;
            b.pubnub.publish({
                channel: b.pubnub.setup.channel,
                message: a,
                callback: function(a) {
                    b.onsend({
                        data: a
                    })
                }
            })
        }, c.prototype.close = function() {
            var a = this;
            a.pubnub.unsubscribe({
                channel: a.pubnub.setup.channel
            }), a.readyState = a.CLOSED, a.onclose({})
        }, a.exports = c
    }])
});